import http from "@/utils/http";

function joinClientId(data = {}) {
  let doMain = JSON.parse(localStorage.getItem("configInfo"));
  let clientId = "";
  if (Array.isArray(doMain)) {
    let findItem = doMain.find(
      (item) => item.hostName === window.location.hostname
    );
    if (findItem && findItem.clientId) {
      clientId = findItem.clientId;
    }
  }
  return { ...data, clientId };
}
// 查询数据
export const getPlatformList = (data) =>
  http({
    url: "/sql/execute/1604086459797733378",
    method: "post",
    data: joinClientId(data),
  });

// BD更新数据
export const getBdUpdateList = (data) =>
  http({
    url: "/sql/execute/1604296370007703553",
    method: "post",
    data,
  });
// IT(可配置化软件)更新数据
export const getItUpdateList = (data) =>
  http({
    url: "/sql/execute/1604112860257058817",
    method: "post",
    data: joinClientId(data),
  });
// DT更新数据
export const getDtUpdateList = (data) =>
  http({
    url: "/sql/execute/1604300905950347265",
    method: "post",
    data,
  });
// OT更新数据
export const getOtUpdateList = (data) =>
  http({
    url: "/sql/execute/1604374022290800641",
    method: "post",
    data,
  });

// 精品应用
export const getApplicationCenterList = (data) =>
  http({
    url: "/sql/execute/1605405766938918914",
    method: "post",
    data: joinClientId(data),
  });
export const getAppClassList = (data) =>
  http({
    url: "/sql/execute/1604033260504088578",
    method: "post",
    data,
  });

//  应用名称
export const getApplicationNameList = (data) =>
  http({
    url: "/transflow/executeCode/getCategoryApp",
    method: "post",
    data: joinClientId(data),
  });

//  咨询留言
export const submitConsulting = (data) =>
  http({
    url: "/sql/execute/1605438420367310872",
    method: "post",
    data: joinClientId(data),
  });


//  获取登录用户信息
export const getInfo = (data) =>
  http({
    // url: "/user/getInfo",
    // method: "post",
    // data: {
    //   ...data,
    //   requstType: "1",
    // },
    url: '/moduleApi/platform/permission/auth/getUserLoginInfo',
    method: 'post',
    data: {
      ...data,
        requstType: "5",
    },
  });

//  获取用户的租户列表信息--旧的用户中心
// export const getUserDept = (data) =>
//   http({
//     url: "/user/getTenantList",
//     method: "post",
//     data: {
//       ...data,
//       requstType: "1",
//     },
//   });
  //  获取用户的租户列表信息--新的用户中心
export const getUserDept = (data) =>
  http({
    url: `/moduleApi/platform/ucenter/auth/getUserClient`,
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
  });

//  用户登录接口
export const signLgin = (data) =>
  http({
    url: "/user/login",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

/**
 * 综合登录(包含账号登录、手机验证吗、钉钉扫码)
 */
export const userCenterLogin = (data, showLoading = false) => {
  return http({
    url: "/moduleApi/platform/permission/auth/userCenterLogin",
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
    headers: {
      'equip-type': localStorage.getItem('equipType') || 1,
      'Request-Source': 'android',
      'equip-id': localStorage.getItem('equipId') || 1,
    },
    showLoading
  });
}

// 登录内部登录接口
export const internalLogin = (data, showLoading = false) => {
  return http({
    url: "/moduleApi/platform/ucenter/auth/internalLoginPC",
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
    showLoading
  });
}

//  用户登录接口
export const loginDingApp = (data) =>
  http({
    url: "/user/loginDingApp",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  用户注册接口
export const register = (data) =>
  http({
    url: "/signIn/register",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

  //  用户注册接口
export const authRegister = (data) =>
  http({
    url: '/moduleApi/platform/ucenter/auth/register',
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
  });

//  简单租户列表接口
export const tenantList = (data) =>
  http({
    url: "/tenant/list",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  手机号验证接口
export const phoneValid = (data) =>
  http({
    url: "/signIn/phoneValid",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  密码重置接口
export const resetPwd = (data) =>
  http({
    url: "/signIn/reset/pwd",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

  /**
 * 短信重置密码
 * @param {*} data 
 * @param {*} showLoading 
 * @returns 
 */
export const changePwByMoble = (data, showLoading = true) => {
  return http({
    url: `/moduleApi/platform/ucenter/auth/changePwByMoble`,
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
    showLoading
  });
}

//  获取短信验证码
export const sendSms = (data) =>
  http({
    url: "/sms/sendSms",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

  /**
* 发送验证码
*/
export const sendSmsByType = (data) => {
  return http({
    url: "/cloudnest/api/moduleApi/platform/ucenter/auth/sendSmsByType",
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
  });
}

//  添加成员接口
export const addMember = (data) =>
  http({
    url: "/tenant/addMember",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  修改成员接口
export const editMember = (data) =>
  http({
    url: "/tenant/editMember",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  删除成员接口
export const deleteMember = (data) =>
  http({
    url: "/tenant/deleteMember",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  注册时租户创建接口
export const addTenant = (data) =>
  http({
    url: "/signIn/addTenant",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  租户创建并绑定用户接口
export const addTenantAndBind = (data) =>
  http({
    url: "/signIn/addTenantAndBind",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  租户升级接口
export const upgrade = (data) =>
  http({
    url: "/tenant/upgrade",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  邀请码接口
export const inviteCode = (data) =>
  http({
    url: "/user/inviteCode",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
//  校验wxid,dingId,invityCode
export const checkCode = (data) =>
  http({
    url: "/user/checkCode",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  校验登录
export const checkToken = (data) =>
  http({
    url: "/user/checkToken",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  刷新token有效期
export const refreshToken = (data) =>
  http({
    url: "/user/refreshToken",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  用户关联租户简单列表接口
export const simpleTenant = (data) =>
  http({
    url: "/tenant/listByUid/" + data,
    method: "get",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  询该租户下的所有应用
export const allApplication = (data) =>
  http({
    url: "/sql/execute/1655879482705698817",
    method: "post",
    data: {
      ...data,
    },
  });

//  询该租户下的查询条件
export const applicationSearch = (data) =>
  http({
    url: "/sql/execute/1641413945696509954",
    method: "post",
    data: {
      ...data,
    },
  });

//  获取关联应用的字典
export const applicationCorrelation = (data) =>
  http({
    url: "/sql/execute/1604109706148839425",
    method: "post",
    data: {
      ...data,
    },
  });

//  查询该租户下的应用是否存在
export const judegeTenant = (data) =>
  http({
    url: "/sql/execute/1641627738800185345",
    method: "post",
    data: {
      ...data,
    },
  });

//  查询该租户下的应用是否存在
export const addOpen = (data) =>
  http({
    url: "/sql/execute/1641627739374804994",
    method: "post",
    data: {
      ...data,
    },
  });

//  查询tree
export const departmentTree = (data) =>
  http({
    url: "/department/tree",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  查询tree
export const departmentList = (data) =>
  http({
    url: "/department/list",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  新增
export const departmentAdd = (data) =>
  http({
    url: "/department/add",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  修改

export const departmentUpdate = (data) =>
  http({
    url: "/department/update",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

 
//  查询部门用户列表
export const departUserList = (data) =>
http({
  url: "/department/userList",
  method: "post",
  data: {
    ...data,
    requstType: "1",
  },
});
  
//  查询page
export const departmentPage = (data) =>
  http({
    url: "/department/page",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//  删除
export const departmentDelete = (data) =>
  http({
    url: "/department/delete/" + data,
    method: "post",
    data: {
      requstType: "1",
    },
  });

//  组织禁用
export const departmentUnable = (data) =>
  http({
    url: "/department/unable/" + data,
    method: "post",
    data: {
      requstType: "1",
    },
  });

// 组织启用
export const departmentEnable = (data) =>
  http({
    url: "/department/enable/" + data,
    method: "post",
    data: {
      requstType: "1",
    },
  });

//  租户禁用
export const freeze = (data) =>
  http({
    url: "/tenant/freeze",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 启用租户接口
export const unfreeze = (data) =>
  http({
    url: "/tenant/unfreeze",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 成员禁用
export const invalidMemberApi = (data) =>
  http({
    url: "/tenant/invalidMember",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 成员启用
export const validMemberApi = (data) =>
  http({
    url: "/tenant/validMember",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 成员page
export const departmentUserPage = (data) =>
  http({
    url: "/department/userPage",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 根据手机号查询用户/user/getByMobile/{mobile}

export const getBymobile = (data) =>
  http({
    url: "/user/getByMobile/" + data,
    method: "post",
    data: {
      requstType: "1",
    },
  });

// 邀请码验证并绑定租户接口
// export const validAndBind = (data) =>
//   http({
//     url: "/user/validAndBind",
//     method: "post",
//     data: {
//       ...data,
//       requstType: "1",
//     },
//   });
export const validAndBind = (data) =>
  http({
    url: `/moduleApi/platform/ucenter/clientInvite/validAndBind`,
    method: 'post',
    data: {
      ...data,
      requstType: "5",
    },
  });

// 邀请码验证接口

export const userValid = (data) =>
  http({
    url: "/user/valid",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 生成邀请码

export const invitfCode = (data) =>
  http({
    url: `/user/invitfCode?orgId=${data}`,
    method: "get",
    data: {
      requstType: "1",
    },
  });

// 确认邀请码
export const confirmCode = (data) =>
  http({
    url: "/user/confirm",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 新增成员
export const addUser = (data) =>
  http({
    url: "/department/addUser",
    method: "get",
    data: {
      requstType: "1",
    },
  });
// 个人中心-修改信息接口
export const editSelfApi = (data) =>
  http({
    url: "/user/edit/self",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 修改密码接口
export const editPwApi = (data) =>
  http({
    url: "/user/change/pwd",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
/**
 * 密码修改
 */
export const changePw = (data) => {
  return http({
    url: "/cloudnest-develop/api/moduleApi/platform/ucenter/auth/changePw",
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
    headers: {
      "Equip-id": 1,
      "Equip-type": localStorage.getItem("equipType"),
    }
  });
}
/**
 * 退出组织
 */
export const leaveOrg = (data) => {
  return http({
    url: "/cloudnest-develop/api/moduleApi/platform/ucenter/sysOrgUser/leaveOrg",
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
    headers: {
      "Equip-id": 1,
      "Equip-type": localStorage.getItem("equipType"),
    }
  });
}

// 从dingding同步企业员工接口
export const dingtbApi = (data) =>
  http({
    url: "/ding/syncUser",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 设置钉钉配置接口
export const setDingApi = (data) =>
  http({
    url: "/tenant/dingConfig",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 租户修改接口
export const editClientApi = (data) =>
  http({
    url: "/tenant/edit",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 查询钉钉配置接口
export const getDingApi = (data) =>
  http({
    url: "/tenant/dingConfigInfo",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
  
    // 从dingding同步员工信息接口
export const syncEmployeeRoster = (data) =>
http({
  url: "/ding/syncEmployeeRoster",
  method: "post",
  data: {
    ...data,
    requstType: "1",
  },
});

// 从dingding同步组织
export const dingtbOgApi = (data) =>
  http({
    url: "/ding/syncDepartment",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 已发布列表
export const publishListApi = (data) =>
  http({
    url: "/transflow/executeCode/app_list_publish",
    method: "post",
    data: {
      ...data,
    },
  });
// 删除
export const delPublishApp = (data) =>
  http({
    url: "/transflow/executeCode/app_del",
    method: "post",
    data: {
      ...data,
    },
  });
// 编辑
export const editPublishApp = (data) =>
  http({
    url: "/transflow/executeCode/app_edit",
    method: "post",
    data: {
      ...data,
    },
  });
// 上架
export const saleAppApia = (data) =>
  http({
    url: `/ntiAppMarket/shelf/${data.tradeVersionId}/${data.appCode}`,
    method: "post",
    data: {
      ...data,
      requstType: "2",
    },
  });
export const saleAppApib = (data) =>
  http({
    url: "/transflow/executeCode/app_off_shelf",
    method: "post",
    data: {
      ...data,
    },
  });
// 下架
export const shelvesAppApia = (data) =>
  http({
    url: `/ntiAppMarket/remove/${data.tradeVersionId}`,
    method: "post",
    data: {
      ...data,
      requstType: "2",
    },
  });
// 开通记录列表查询
export const sysAppCientListApi = (data) =>
  http({
    url: "/transflow/executeCode/sysAppCientList",
    method: "post",
    data: {
      ...data,
    },
  });
// 保存租户应用配置
export const saveDingInfoApi = (data) =>
  http({
    url: "/transflow/executeCode/saveClientAppConfig",
    method: "post",
    data: {
      ...data,
    },
  });
// 应用启用和停用
export const useAppApi = (data) =>
  http({
    url: "/transflow/executeCode/sysAppCientEnable",
    method: "post",
    data: {
      ...data,
    },
  });
// 发布外部应用
export const publishAppApi = (data) =>
  http({
    url: "/rtrade/application/publish",
    method: "post",
    data: {
      ...data,
      requstType: "3",
    },
  });
// 设置
export const setAppApi = (data) =>
  http({
    url: "/transflow/executeCode/saveClientAppSetting",
    method: "post",
    data: {
      ...data,
    },
  });
export const setGetApi = (data) =>
  http({
    url: "/transflow/executeCode/queryClientAppSetting",
    method: "post",
    data: {
      ...data,
    },
  });
// 查询租户信息
export const getClientInfoApi = (data) =>
  http({
    url: "/tenant/detail",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
export const getDingInfoApi = (data) =>
  http(
    {
      url: "/tenant/dingConfigInfo",
      method: "post",
      data: {
        ...data,
        requstType: "1",
      },
    },
    {
      showErr: false,
    }
  );
// 部门用户树接口
export const deptUserTree = (data) =>
  http({
    url: "/department/deptUserTree",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 部门用户树接口
export const sendHeartbeat = (data) =>
  http({
    url: "/signIn/sendHeartbeat",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
export const VtdeptUserTree = (data) =>
  http({
    url: "/department/v2/deptUserTree",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

// 获取当期租户下用户所属部门id列表接口

export const departmentIds = (data) =>
  http({
    url: "/department/deptIds",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });

//部门用户树接口2
export const deptUserTreeV = (data) =>
  http({
    url: "/department/v2/deptUserTree",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
//f复制应用
export const appbuildComponentApi = (data) =>
  http({
    url: "/app/component",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//获取已上架/待上架列表接口
export const appbuildApi = (data) =>
  http({
    url: "/app/page",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//获取组件id
export const componentApi = (data) =>
  http({
    url: `/app/component?appId=${data}`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });
//启用/停用应用
export const appbuildEnableApi = (data) =>
  http({
    url: "/app/enable",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//删除
export const appbuildRemoveApi = (data) =>
  http({
    url: "/app/remove",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//上架申请
export const appbuildOnlineApi = (data) =>
  http({
    url: "/app/online",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//应用下架
export const appbuildOffShelfApi = (data) =>
  http({
    url: "/app/offShelf",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//修改应用
export const appbuildUpdateApi = (data) =>
  http({
    url: "/app/update",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//应用详情页
export const appbuildDetailApi = (data) =>
  http({
    url: "/app/detail",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });

// 组件类型
export const categoryTreeApi = (data) =>
  http({
    url: `/category/tree?moduleType=${data.moduleType}&platformType=${data.platformType}`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });

//组件分页
export const componentPageApi = (data) =>
  http({
    url: "/component/page",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
//组件详情
export const componentDetailApi = (data) =>
  http({
    url: `/component/detail?id=${data}`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });

//应用设置
export const componentAppSettingApi = (data) =>
  http({
    url: "/component/appSetting",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });

//上传图片
export const filterUpload = (data) =>
  http({
    url: "/file-server/upload",
    method: "post",
    data,
  });

//组件菜单树
export const componentMenuTreeApi = (data) =>
  http({
    url: "/component/componentMenuTree?componentId=" + data.id,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });

//获取页面配置项
export const listAttrConfigApi = (data) =>
  http({
    url:
      "/config/listAttrConfig?componentId=" +
      data.componentId +
      "&menuId=" +
      data.menuId +
      "&appCode=" +
      data.appCode,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });

//应用设置
export const appSettingApi = (data) =>
  http({
    url: "/component/appSetting",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });

// 角色列表接口
export const getRoleListApi = (data) =>
  http({
    url: "/role/list",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 创建角色
export const addRoleApi = (data) =>
  http({
    url: "/role/add",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 删除角色
export const delRoleApi = (data) =>
  http({
    url: "/role/del",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 编辑角色名
export const editRoleApi = (data) =>
  http({
    url: "/role/edit",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 角色添加用户接口
export const roleFpApi = (data) =>
  http({
    url: "/role/addUser",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 角色添加用户回显接口
export const roleRelateUserApi = (data) =>
  http({
    url: "/role/usersByRoleId",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
export const getRoleUserPageApi = (data) =>
  http({
    url: "/role/userPage",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 复制角色接口
export const copyRoleApi = (data) =>
  http({
    url: "/role/copy",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 角色移除用户接口
export const roleRemoveApi = (data) =>
  http({
    url: "/role/removeUser",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 角色权限树
export const getAuthApi = () =>
  http({
    url: "/permission/trees",
    method: "get",
    data: {
      requstType: "1",
    },
  });

// 角色分配权限接口
export const relatePermissionApi = (data) =>
  http({
    url: "/role/relatePermission",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 查看角色分配权限
export const getPermissionApi = (data) =>
  http({
    url: "/permission/idsByRoleId",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 租户转让管理员接口
export const tenanTransferApi = (data) =>
  http({
    url: "/tenant/transfer",
    method: "post",
    data: {
      ...data,
      requstType: "1",
    },
  });
// 引导式-应用暂存   创建应用时，进入登录页设置前调用
export const guidedAppStagingApi = (data) =>
  http({
    url: "/component/guidedAppStaging",
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
// 通过编码获取页面配置项
export const listAttrConfigByCode = (data) =>
  http({
    url: data.page == 'menu' ? `/config/listAttrConfigUnderline?componentId=${data.componentCode}&menuId=${data.menuCode}&appCode=${data.appCode}` : `/config/listAttrConfigByCodeUnderline?componentCode=${data.componentCode}&menuCode=${data.menuCode}&appCode=${data.appCode}`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });
// 关联组件信息
export const refComponentApi = (data) =>
  http({
    url: `/component/refComponent?componentId=${data.componentId}`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });
// 通过编码获取页面配置项
export const componentMenuListApi = (data) =>
  http({
    url: `/component/componentMenuList?appId=${data.appId}&componentIdList=${data.componentIdList}`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });
// 通过编码获取页面配置项
export const idGenerateApi = (data) =>
  http({
    url: `/app/idGenerate`,
    method: "get",
    data: {
      ...data,
      requstType: "4",
    },
  });
// 引导式-应用设置
export const guidedAppSettingApi = (data) =>
  http({
    url: `/component/guidedAppSetting`,
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });
// 引导式-应用设置
export const guidedAppCompleteApi = (data) =>
  http({
    url: `/component/guidedAppComplete?appId=${data.appId}`,
    method: "post",
    data: {
      requstType: "4",
    },
  });
// 引导式-应用编辑
export const guidedAppEditApi = (data) =>
  http({
    url: `/component/guidedAppEdit`,
    method: "post",
    data: {
      ...data,
      requstType: "4",
    },
  });

  //应用详情页
export const guidedDetailApi = (data) =>
http({
  url: "/app/guidedDetail",
  method: "post",
  data: {
    ...data,
    requstType: "4",
  },
}); 
// 租户转让管理员接口
export const getAuthInfo = (data) =>
  http({
    url: "/permission/listByUserId",
    method: "get",
    data: {
      requstType: "1",
    },
  });
// 租户转让管理员接口
export const addClient = (data) =>
  http({
    url: "/moduleApi/platform/permission/client/addClient",
    method: "post",
    data: {
      ...data,
      requstType: "5",
    },
  });

  // 今天国际=历程
export const getMediaList = (data) =>
  http({
    url: "https://www.nti56.com/backend/cms/open/api/media/list?slideshowType=development_history&language=zh&pageSize=99",
    method: "get",
    
  });
export const listWithApps = (data) =>
  http({
    url: `/appstore/moduleApi/market/shop/category/listWithApps/${data?.id}`,
    method: "get",
    data: {
      ...data,
      requstType: "5",
    },
  });

  // 列表查询
  export const appList = (data) =>
    http({
      url: `/appstore/moduleApi/market/shop/app/list`,
      method: "get",
      data: {
        ...data,
        requstType: "5",
      },
    });