import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getInfo } from "@/api/platformCenter";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clientId: "1010203040506070809",
    appCode: "iiot",
    userInfo: {
      username: "",
      realName: "",
      isDevelop: 0,
      mobile: "",
      lastLoginTime: "",
      loginCount: 0,
      userId: "",
      id: "",
      isDevelop: 0,
      userHeadPortrait: ""
    },
    userDeptName: "",
    authMain: [],
    clients: [],
  },
  getters: {},
  mutations: {
    setClientId(state, data) {
      state.clientId = data
    },
    setUserDeptName(state, data) {
      state.userDeptName = data
    },
    setUserInfo(state, data) {
      state.clients = data?.clients || []
      state.userInfo = {
        ...state?.userInfo,
        ...data?.userInfo,
        realName: data?.userInfo?.empName || '',
        // mobile: data.userInfo.mobile,
        userId: data?.userInfo?.id || '',
        id: data?.userInfo?.id || '',
      }
    },
    setAuth(state, data) {
      state.authMain = data
    },
  },
  actions: {
    getUserInfo() {
      getInfo().then((res) => {
        
      })
      const data = {
          username: "user_test",
          realName: "张三",
          isDevelop: 0,
          mobile: "15960112233",
          lastLoginTime: "2023-03-27 19:23:44",
          loginCount: 4,
      };
      
      let userInfo = data.data;
      // sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  modules: {
    updateState: (state, paload) => {
      for (const key in paload) {
        if (paload.hasOwnProperty(key)) {
          const element = paload[key];
          if (Object.prototype.toString.call(element) === '[object Object]') {
            state[key] = {
              ...state[key],
              ...element
            };
          } else {
            state[key] = element;
          }

        }
      }
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer (state) {
        return state;
      }
    }
  )]
});
